import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Navbar from "../components/Navbar";
import styled from "styled-components";
import pfp from "../images/pfp-600x400.jpg";

const About = () => (
  <Layout>
    <SEO title="About" />
    <Navbar />
    <AboutContainer>
      <ImgContainer>
        <Image src={pfp} className="fade-in__first" />
      </ImgContainer>
      <ParagraphContainer className="fade-in__second">
        <Paragraph>
          <b>Hi, my name is Michael Chun.</b>
        </Paragraph>
        <Paragraph>
          I’m a self-driven software developer who enjoys solving technical
          challenges and has a passion for mission-driven work.
        </Paragraph>
        <Paragraph>
          Having worked in various roles from QA, IT, and UX, I have developed
          an interdisciplinary skillset and bring strong problem solving,
          product strategy, and technical skills, allowing me to build solutions
          in a holistic way.
        </Paragraph>
        <Paragraph>
          Currently, I’m looking to work with some amazing people on impactful
          projects.
        </Paragraph>
        <Paragraph>
          When I'm not coding, I'm usually dabbling in{" "}
          <a
            href="https://flickr.com/photos/michaelchun03/albums/72157712391449907"
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            photography
          </a>
          , cooking new recipes, playing music, or obsessing over my cat{" "}
          <a
            href="https://www.instagram.com/hello_mango_cat/"
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mango
          </a>
          .
        </Paragraph>
        <br />
        <ParagraphSocial>
          <Social>Email</Social>
          <a
            href="mailto:michael.chun03@gmail.com"
            target="_blank"
            className="underline"
            rel="noopener noreferrer"
          >
            michael.chun03@gmail.com
          </a>{" "}
          <br />
          <Social>Github</Social>
          <a
            href="https://github.com/mgc3"
            target="_blank"
            className="underline"
            rel="noopener noreferrer"
          >
            github.com/mgc3
          </a>{" "}
          <br />
          <Social>LinkedIn</Social>
          <a
            href="https://www.linkedin.com/in/michaelchun03"
            target="_blank"
            className="underline"
            rel="noopener noreferrer"
          >
            linkedin.com/in/michaelchun03
          </a>{" "}
          <br />
        </ParagraphSocial>
      </ParagraphContainer>
    </AboutContainer>
  </Layout>
);

export default About;

const AboutContainer = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: white;
`;

const ParagraphContainer = styled.div``;

const Paragraph = styled.p`
  line-height: 1.75;
  font-size: 20px;
  letter-spacing: -0.3px;
  font-weight: 300;
`;

const ParagraphSocial = styled.p`
  line-height: 1.75;
  font-size: 18px;
  letter-spacing: -0.3px;
  font-weight: 300;
  @media (max-width: 400px) {
    font-size: 16px;
  }
`;

const ImgContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  max-width: 100%;
`;

const Social = styled.span`
  width: 120px;
  display: inline-block;
  @media (max-width: 400px) {
    width: 80px;
  }
`;
